import React, {useState} from 'react';
import './App.css';
import home from './assets/img/home.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, AppBar, Drawer, Grid } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import logo from './assets/img/logo.png';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { BiHomeAlt } from 'react-icons/bi'
import { FaWhatsapp, FaFacebook } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'
import { VscClose } from 'react-icons/vsc'
import businessUnitFranca from './assets/img/2024-01-02.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${home})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    opacity: .85,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 779px)": {
      backgroundPosition: 'center'
    }
  },
  title: {
    color: '#FFF',
    fontWeight: 'bold'
  },
  subtitle: {
    color: '#FFF',
    marginTop: '10px',
    fontWeight: 'lighter',
    "@media (max-width: 779px)": {
      display: 'none'
    }
  },
  subtitleMobile: {
    color: '#FFF',
    marginTop: '10px',
    fontWeight: 'lighter',
    "@media (min-width: 780px)": {
      display: 'none'
    }
  },
  contact: {
    backgroundColor: '#65CF72',
    textTransform: 'none',
    color: '#FFF',
    borderRadius: '15px',
    width: '220px',
    '&:hover': {
      backgroundColor: 'rgba(101, 207, 114, 0.9)'
    }
  },
  units: {
    backgroundColor: '#CDCF65',
    textTransform: 'none',
    color: '#FFF',
    borderRadius: '15px',
    width: '180px',
    "@media (min-width: 780px)": {
      marginLeft: '20px'
    },
    "@media (max-width: 779px)": {
      marginTop: '15px'
    },
    '&:hover': {
      backgroundColor: 'rgba(205, 207, 101, 0.9)'
    }
  },
  buttons: {
    marginTop: '15px'
  },
  titleAppBar: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  login: {
    backgroundColor: '#FFF',
    textTransform: 'none',
    color: '#000',
    borderRadius: '15px',
    paddingRight: '20px',
    paddingLeft: '20px'
  },
  media: {
    height: 140,
  },
  drawerContent: {
    width: '40vw', 
    padding: '20px',
    "@media (max-width: 779px)": {
      width: '89vw'
    }
  }
}));

function App() {

  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appbar}>
        <Toolbar>
          <img draggable="false" alt="Arte Colorida" src={logo} style={{width: '80px'}} />
          <Typography variant="h6" className={classes.titleAppBar}></Typography>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/escolainfantilartecolorida" style={{textDecoration: 'none', color: '#FFF'}}>
            <AiFillInstagram style={{fontSize: '32px'}} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/escolainfantilartecolorida" style={{textDecoration: 'none', color: '#FFF'}}>
            <FaFacebook style={{fontSize: '26px', marginLeft: '6px', marginRight: '20px'}} />
          </a>
          {/*<Button size="large" className={classes.login}><BiUserCircle style={{fontSize: '22px'}} /> &nbsp; Entrar</Button>*/}
        </Toolbar>
      </AppBar>
      <Container>
        <div>
          <Typography variant="h4" className={classes.title}>Especialista em Educação Infantil</Typography>
          <Typography variant="h6" className={classes.subtitle}>
            Em 2015 surgimos com o propósito de proporcionar <br></br> educação de qualidade à crianças de 0 até 6 anos, <br></br> preparando elas para um futuro brilhante.
          </Typography>
          <Typography variant="h6" className={classes.subtitleMobile}>
            Em 2015 surgimos com o propósito de proporcionar educação de qualidade à crianças de 0 até 6 anos, preparando elas para um futuro brilhante.
          </Typography>
          <div className={classes.buttons}>
            <a style={{textDecoration: 'none'}} target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=5516991791837&text=Ol%C3%A1!%20Gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20escola.">
              <Button size="large" className={classes.contact}><FaWhatsapp style={{fontSize: '22px'}} /> &nbsp; Entrar em contato</Button>
            </a>
            <Button onClick={() => {setDrawer(true)}} size="large" className={classes.units}><BiHomeAlt style={{fontSize: '22px'}} /> &nbsp; Unidades</Button>
          </div>
        </div>
      </Container>
      <Drawer anchor="left" open={drawer} onClose={() => {setDrawer(false)}}>
        <div className={classes.drawerContent}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h6" style={{color: '#707072'}}>Unidades</Typography>
            <VscClose style={{color: '#707072', fontSize: '28px', cursor: 'pointer'}} onClick={() => {setDrawer(false)}} />
          </div>
          <br></br>
          <Grid spacing={3} container>
            <Grid item md={5} xs={12}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={businessUnitFranca}
                    title="Unidade Franca"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="body1" component="h2">
                      Unidade Franca
                    </Typography>
                    <Typography gutterBottom color="textSecondary" variant="body2" component="h2">
                      De 0 a 6 anos
                    </Typography>
                    <br></br>
                    <Typography variant="body2" color="textSecondary" component="p">
                      <MdLocationOn /> R. Afonso Pena, 1282 - Vila Formosa, Franca - SP, 14401-141
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </div>
  );
}

export default App;